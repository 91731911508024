import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  ICON_SIZE,
  PADDING_CSS,
  ROUNDED_CSS,
  TEXT_SIZE_CSS,
  UNDERLINE_CSS,
  WIDTH_HEIGHT_CSS,
} from './sparkButtonConstants';
import type { ColorScheme, SparkButtonProps } from './types';

export function useSparkButton(
  look: ComputedRef<string>,
  color: ComputedRef<SparkButtonProps['color']>,
  shape: ComputedRef<SparkButtonProps['shape']>,
  size: ComputedRef<SparkButtonProps['size']>,
  disabled: ComputedRef<SparkButtonProps['disabled']>,
  colorSchemes: Record<string, ColorScheme>,
) {
  const roundedCss = computed(() => {
    return ROUNDED_CSS[shape.value][size.value];
  });

  const colorScheme = computed(() => {
    return colorSchemes[look.value];
  });

  const bgCss = computed<string>(() => {
    if (!colorScheme.value) {
      return '';
    }
    return disabled.value
      ? (colorScheme.value.backgroundDisabled?.[color.value] ?? '')
      : (colorScheme.value.background?.[color.value] ?? '');
  });

  const borderCss = computed<string>(() => {
    if (!colorScheme.value) {
      return '';
    }
    return disabled.value
      ? (colorScheme.value.borderDisabled?.[color.value] ?? '')
      : (colorScheme.value.border?.[color.value] ?? '');
  });

  const textCss = computed<string>(() => {
    if (!colorScheme.value) {
      return '';
    }
    return disabled.value
      ? (colorScheme.value.textDisabled?.[color.value] ?? '')
      : (colorScheme.value.text?.[color.value] ?? '');
  });

  const paddingCss = computed(() => {
    if (shape.value === 'circle') {
      return '';
    }
    return PADDING_CSS[shape.value]?.[size.value] ?? '';
  });

  const textSizeCss = computed(() => {
    if (shape.value === 'circle') {
      return '';
    }
    return TEXT_SIZE_CSS[shape.value]?.[size.value] ?? '';
  });

  const widthHeightCss = computed(() => {
    if (shape.value === 'pill' || shape.value === 'rounded') {
      return '';
    }
    return WIDTH_HEIGHT_CSS[shape.value]?.[size.value] ?? '';
  });

  const underlineCss = computed(() => {
    return disabled.value ? '' : (UNDERLINE_CSS?.[look.value] ?? '');
  });

  const gapCss = computed(() => {
    return size.value === 'xs' ? 'gap-x-1' : 'gap-x-2';
  });

  const baseCss =
    'transition-all font-medium inline-flex items-center justify-center select-none whitespace-nowrap focus-visible:outline-none';

  const buttonCss = computed<string[]>(() => {
    const result = [
      baseCss,
      bgCss.value,
      borderCss.value,
      textCss.value,
      paddingCss.value,
      roundedCss.value,
      textSizeCss.value,
      widthHeightCss.value,
      underlineCss.value,
      gapCss.value,
    ].filter((x) => x);

    if (disabled.value) {
      result.push('cursor-not-allowed');
    }

    return result;
  });

  const iconSize = computed(() => ICON_SIZE[size.value]);

  const style = computed(() => ({
    '--radiate-color': colorScheme.value?.radiateColor?.[color.value] ?? '',
  }));
  return {
    buttonCss,

    // for custom button
    iconSize,
    roundedCss,
    baseCss,
    paddingCss,
    textSizeCss,
    style,
  };
}
