import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class AccessService extends ApiBase {
  getBeckMapData() {
    return this.$http
      .get<{
        data: components['schemas']['AccessBeckMapDTO'];
      }>('v1.0/access/beck-maps/latest')
      .then((response) => response?.data.data);
  }

  getTerminalSlots() {
    return this.$http
      .get<{
        data: components['schemas']['AccessTerminalSlotDTO'][];
      }>('v1.0/access/terminal-slots')
      .then((response) => response?.data.data);
  }

  getTerminals() {
    return this.$http
      .get<{
        data: components['schemas']['TerminalDTO'][];
      }>('v1.0/access/terminals')
      .then((response) => response?.data.data);
  }

  getLatestTerminalCosts() {
    return this.$http
      .get<
        components['schemas']['ListResponse_SparkRTerminalReleaseDTO_']
      >('v1.0/access/sparkr/latest')
      .then((response) => response?.data?.data);
  }

  getTerminalCostsByReleaseDate(releaseDate: string) {
    return this.$http
      .get<
        components['schemas']['ListResponse_SparkRTerminalReleaseDTO_']
      >('v1.0/access/sparkr/' + releaseDate)
      .then((response) => response?.data?.data);
  }

  getSparkRReferenceData() {
    return this.$http
      .get<
        components['schemas']['DetailResponse_SparkRReleaseRefDataDTO_']
      >('v1.0/access/sparkr/reference-data')
      .then((response) => response?.data?.data);
  }

  getSparkRTerminalsChangelog() {
    return this.$http
      .get<
        components['schemas']['DetailResponse_SparkRChangelogDTO_']
      >(`v1.0/sparkr/changelog/current`)
      .then((response) => response?.data?.data);
  }

  getFobNetbacksFilters() {
    return this.$http
      .get<
        components['schemas']['DetailResponse_SparkRFobNetbackFiltersDTO_']
      >('v1.0/access/sparkr/fob-netbacks/filters')
      .then((response) => response?.data?.data);
  }

  getFobNetbacksByReleaseDate(releaseDate: string) {
    return this.$http
      .get<
        components['schemas']['ListResponse_SparkRTerminalFobNetbackDTO_']
      >(`v1.0/access/sparkr/fob-netbacks/releases/${releaseDate}`)
      .then((response) => response?.data?.data);
  }

  getCargoMainCurve(releaseDate: string) {
    return this.$http
      .get<
        components['schemas']['DetailResponse_MainCurveDTO_']
      >(`v2.0/price-releases/lng-basis::physical/${releaseDate}/main-curve`)
      .then((response) => response?.data?.data);
  }

  getNetbackOverlay(releaseDate: string) {
    return this.$http
      .get<
        components['schemas']['DetailResponse_PAPINetbackForAccessOverlayDTO_']
      >(`/v2.0/netbacks/${releaseDate}/main-curve`)
      .then((response) => response?.data?.data);
  }
}
